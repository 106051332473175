import { Container, Typography, colors } from '@mui/material';

function ResponsiveTextSection() {
  return (
    <Container maxWidth="sm" sx={{
      mt: 12
    }}>
      <Typography
        variant="h4"
        align="center"
        color={colors.grey[800]}
        fontWeight='bold'
      >
        Glaucar - Guinchos
      </Typography>
      <Typography
        variant="body1"
        align="justify"
        color={colors.grey[800]}
        mt={6}
      >
        <p>
          Você está em apuros na estrada? Não se preocupe! Atendemos em toda a região de Jundiaí, Campinas, Várzea Paulista, Campo Limpo e Sorocaba.
        </p>
        <p>
          Com uma equipe experiente e equipamentos de última geração, estamos prontos para lidar com qualquer situação,
          desde panes mecânicas até pneus furados.
          Não importa onde você esteja, estamos sempre por perto para ajudar.
        </p>
        <p>
          Nossa missão é proporcionar um serviço confiável e eficiente, garantindo que você retorne à estrada rapidamente e com segurança.
          Com preços acessíveis e atendimento 24 horas por dia, 7 dias por semana, estamos sempre à disposição para atender às suas necessidades
          de reboque e assistência.
        </p>
        <p>
          Não deixe contratempos na estrada arruinar seu dia. Conte conosco para estar lá quando você mais precisar. Ligue agora mesmo 
          e descubra por que somos o guincho de confiança de tantos motoristas em toda a região!
        </p>
      </Typography>
    </Container>
  );
}

export default ResponsiveTextSection;