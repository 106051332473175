import ResponsiveAppBar from "../components/appBar/appBar";
import { MainSection } from "./mainSection/mainSection";
import { Informations } from "./informations/informations";
import { Services } from "./services/Services";
import { Wathsapp } from "../components/whatsapp/whatsapp";
import { Contact } from "./contact/Contact";
import { GroupImages } from "./groupServices/groupServices";
import { Footer } from "./footer/footer";
import ResponsiveTextSection from "../TextSection/TextSection";

export default function App() {
  return (
    <div style={{ overflowX: 'hidden' }}>
      <ResponsiveAppBar />
      <MainSection />
      <Services />
      <Informations />
      <ResponsiveTextSection />
      <Contact />
      <GroupImages />
      <Footer />
      <Wathsapp />
    </div>
  )
}