import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InstagramIcon from '@mui/icons-material/Instagram'
import WhatsApp from '@mui/icons-material/WhatsApp'

import bg from '../../resources/images/logo_with_bg.png'
import { green, orange } from '@mui/material/colors';
import { sendInstagram, sendWhatsapp } from '../../resources/ts/services';



export default function Instagram() {
   return (
      <Card sx={{ maxWidth: {md:645, xs: 345} }}>
         <CardMedia
            sx={{ 
               height: 140,   
            }}
            image={bg}
            title="bg glaucar"
         />
         <CardContent>
            <Typography gutterBottom variant="h5" component="div">
               Glaucarguincho
            </Typography>
            <Typography variant="body2" color="text.secondary">
               Acompanhe de perto nosso trabalho acessando nossas
               redes sociais disponíveis
            </Typography>
         </CardContent>
         <CardActions>
            <Button size="small"
               onClick={sendInstagram}
            >
               Instagram 
               <InstagramIcon sx={{
                  ml:1,
                  color: orange[900]
               }} />
            </Button>
            <Button size="small"
               onClick={sendWhatsapp}
            >
               Whatsapp
               <WhatsApp sx={{
                  ml:1,
                  color: green[900]
               }} />
            </Button>
         </CardActions>
      </Card>
   );
}
