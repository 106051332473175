import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import imageCar from '../../resources/images/winch-car.png';
import logo from '../../resources/images/logo_without_bg.png'
import { colors } from '../../template/colors'

const pages = ['Início', 'Serviços', 'Contato', '(11) 98875-0370'];
const settings: any = {
   'Início': 'main-section',
   'Serviços': 'services',
   'Contato': 'contacts',
   '(11) 98875-0370': 'contacts'
};

export default function ResponsiveAppBar() {
   const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

   const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
   };
 
   const handleCloseNavMenu = (choose: string) => {
      setAnchorElNav(null);
      const obj = document.getElementById(settings[choose])
      obj?.scrollIntoView({ behavior: 'smooth' })
   };

   return (
      <AppBar position="fixed"
         sx={{
            backgroundColor: colors.bg_dark,
            position: 'sticky'
         }}>
         <Container maxWidth="xl"
            sx={{

            }}
         >
            <Toolbar disableGutters>
               <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="#app-bar-with-responsive-menu"
                  sx={{
                     mr: 2,
                     display: { xs: 'none', md: 'flex' },
                     fontFamily: 'monospace',
                     fontWeight: 700,
                     letterSpacing: '.3rem',
                     color: 'inherit',
                     textDecoration: 'none',

                  }}
               >
                  <img src={imageCar} alt='iconcar' width={80} />
               </Typography>

               <Box sx={{
                  flexGrow: 1,
                  display: { xs: 'flex', md: 'none' },
               }}>
                  <IconButton
                     size="large"
                     aria-label="account of current user"
                     aria-controls="menu-appbar"
                     aria-haspopup="true"
                     onClick={handleOpenNavMenu}
                     color="inherit"
                  >
                     <MenuIcon />
                  </IconButton>
                  <Menu
                     id="menu-appbar"
                     anchorEl={anchorElNav}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                     }}
                     open={Boolean(anchorElNav)}
                     onClose={handleCloseNavMenu}
                     sx={{
                        display: { xs: 'block', md: 'none' },
                     }}
                  >
                     {pages.map((page) => (
                        <MenuItem
                           key={page}
                           onClick={() => handleCloseNavMenu(page)}
                        >
                           <Typography>
                              {page}
                           </Typography>
                        </MenuItem>
                     ))}
                  </Menu>
               </Box>

               <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href="#app-bar-with-responsive-menu"
                  sx={{
                     mr: 2,
                     display: { xs: 'flex', md: 'none' },
                     flexGrow: 1,
                     fontFamily: 'monospace',
                     fontWeight: 700,
                     letterSpacing: '.2rem',
                     color: 'inherit',
                     textDecoration: 'none',
                     height: 100,
                     justifyContent: 'center',
                     alignItems: 'center'
                  }}
               >
                  GLAUCAR
               </Typography>
               <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
                  {pages.map((page) => (
                     <Button
                        key={page}
                        onClick={() => handleCloseNavMenu(page)}
                        sx={{
                           my: 2,
                           color: 'white',
                           display: 'block',
                           ':first-child': {
                              'ml': 0
                           },
                           ml: '70px'
                        }}
                     >
                        {page}
                     </Button>
                  ))}
               </Box>
               <Box
                  sx={{
                     display: { xs: 'flex', md: 'none' },
                     flexGrow: 1,
                     justifyContent: 'right'
                  }}>
                  <img src={imageCar} alt='logo without bg' height={90} />
               </Box>
               <Box sx={{
                  flexGrow: 0,
                  display: { xs: 'none', md: 'flex' }
               }}>
                  <img src={logo} alt='logo without bg' height={90} />
               </Box>
            </Toolbar>
         </Container>
      </AppBar>
   );
}


