import {
   Box,
   Typography
} from "@mui/material"

import './mainSection.css'
import { colors } from "../../template/colors"

export const MainSection = () => {
   return (
      <div id="main-section">
         <Box
            className='section-container'
         >
            <div style={{
               position: 'absolute',
               top: 0,
               left: 0,
               width: '100%',
               height: '100%',
               backgroundColor: 'rgba(0, 0, 0, 0.8)',
               textAlign: 'center',
               alignContent: 'center',
               display: 'flex',
               flexDirection: 'column',
               justifyContent: 'center',
            }}>
               <Typography
                  noWrap
                  component="a"
                  sx={{
                     mt: '2%',
                     fontFamily: 'monospace',
                     fontWeight: 100,
                     letterSpacing: '.3rem',
                     color: 'white',
                     textDecoration: 'none',
                     height: 100,
                     justifyContent: 'center',
                     alignItems: 'center',
                     fontSize: 'calc(10px + 2vw)',
                  }}
               >
                  SERVIÇO DE QUALIDADE

               </Typography> 
               <Typography
                  variant="body1"
                  component="a"
                  alignSelf='center'
                  width='70%'
                  sx={{
                     p: '10px',
                     borderRadius: 10,
                     color: 'white',
                     backgroundColor: colors.bg_red_trans,
                     fontSize: 'calc(10px + 2vw)',
                     mt: '10px'
                  }}
               >
                  GLAUCAR BUSCA IMEDIATA
               </Typography>
               <Typography variant="body1" color='#bdbdbd' mt={6} p={2}
                  sx={{
                     fontSize:{ xs: 15, md: 20 }
                  }}
               >
                  Atendimento rápido, seguro e mais econômico da região.
                  Fazemos viagens na região de Campo Limpo, Várzea Paulista, Jundiaí e Campinas.
               </Typography>
            </div>
         </Box>
      </div>
   )
}